<form name="bookingForm" #form="ngForm" novalidate id="form_48" class="sol" data-form-type="service-booking">
  <!-- Mat stepper wrapper start -->
  <div class="stepper__wrapper">

    <!-- Mat stepper heading start -->
    <div class="stepper__heading">
      <!-- Step 1 heading start -->
      <ng-container *ngIf="stepper.selectedIndex === 0">
        <h3 *ngIf="appTitle;">{{ appTitle }}</h3>
        <h3 *ngIf="!appTitle" class="heading--grouped" style="font-size: 24px;">Quality, value for money servicing for
          every {{ vehicleName }}</h3>
        <h4 *ngIf="appSubtitle">{{ appSubtitle }}</h4>
        <h4 *ngIf="!appSubtitle">Expect the highest standards and great prices when you service your {{ vehicleName }}
        </h4>
      </ng-container>
      <!-- Step 1 heading end -->

      <ng-container *ngIf="stepper.selectedIndex === 1">
        <h3 class="heading--grouped" style="font-size: 24px;">We're here if you need any assistance</h3>
        <!-- <h4>Just call <a href="tel:03301082329" class="body-link">0330 108 2329</a> and we'll contact you shortly

          or <a class="body-link body-link--underline" (click)="onCallbackClick()">click here</a> for a free instant call-back
        </h4> -->
      </ng-container>

      <ng-container *ngIf="stepper.selectedIndex === 2">
        <h3 class="heading--grouped" style="font-size: 24px;">We're here if you need any assistance</h3>
        <!-- <h4>Just call <a href="tel:03301082329" class="body-link">0330 108 2329</a> and we'll contact you shortly

           or <a class="body-link body-link--underline" (click)="onCallbackClick()">click here</a> for a free instant call-back
        </h4> -->
      </ng-container>

    </div>

    <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper>
      <mat-step ngModelGroup="vehicleGroup" [stepControl]="form.controls.vehicleGroup"
        errorMessage="Vehicle details are required." [editable]="formDisabled">
        <ng-template matStepLabel>Your {{ vehicleName }}</ng-template>
        <app-vehicle-details></app-vehicle-details>
      </mat-step>

      <mat-step ngModelGroup="serviceGroup" [stepControl]="form.controls.serviceGroup"
        errorMessage="Service details are required." [editable]="formDisabled">
        <ng-template matStepLabel>Service details</ng-template>
        <app-service-details></app-service-details>
      </mat-step>

      <mat-step errorMessage="Your details are required." [editable]="formDisabled">
        <ng-template matStepLabel>Your details</ng-template>
        <app-personal-details (formResponse)="successfulResponse($event)"></app-personal-details>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</form>


<!-- <div class="xs-col-12 float--left" style="position: absolute; top: 0;">
  <h3>Quality, value for money servicing for every car</h3>
  <h4>Expect the highest standards and great prices when you service your car</h4>
  <p>Please feel free to call us on <a class="body--link">0330 108 2329</a> and we’ll be more than happy to answer any questions you
    may have about your car servicing needs.
  </p>
</div> -->