var FormData = /** @class */ (function () {
    function FormData(vehicleReg, mileage, postcode, postcodeFound, basecolour, canservice, capcode, dofr, encregno, fuel, image, make, model, specref, vehTyp, vehicle, servIntervals, dealerServiceControls, locations, locnimage, _location, dealerAvailability, calendarStartDate, selectedDate, selectedTime, isMOT, servType, servOptionCosts, servAnnualInspectionLbl, servAnnualInspectionCost, servTechOptionSts, servTechOptions, techoptECS, techoptACS, techoptBFC, techoptCoolant, techoptPollen, techoptGearbox, techoptCambelt, annualInspect, seasonalCheck, tyreCheck, adBlue, generalRepMain, recall, selectedService, dealerCourtesyAvailable, courtesyCar, whileUwait, firstName, surname, email, telephone, comments, Address_1, Address_2, Town, County, paymentType, totalPrice, interval, intervalMonth, collectNdeliver, servcosts, isPOA, isTextBased, hasServicePlan, healthCheck, advisorAppointmentSts, servSeasonalAirConSrvPrice, servSeasonalCombinedPrice, servSeasonalDetoxPackPrice, servSeasonalBronzePackPrice, servSeasonalGoldPrice, servSeasonalSilverSrvPrice, servCollectnDeliverCost, servCollectnDeliverLblOvr, keyDropOff, isSelectedDateCorrect, Q658) {
        if (postcodeFound === void 0) { postcodeFound = false; }
        if (servcosts === void 0) { servcosts = ''; }
        if (isSelectedDateCorrect === void 0) { isSelectedDateCorrect = false; }
        if (Q658 === void 0) { Q658 = 'No'; }
        this.vehicleReg = vehicleReg;
        this.mileage = mileage;
        this.postcode = postcode;
        this.postcodeFound = postcodeFound;
        this.basecolour = basecolour;
        this.canservice = canservice;
        this.capcode = capcode;
        this.dofr = dofr;
        this.encregno = encregno;
        this.fuel = fuel;
        this.image = image;
        this.make = make;
        this.model = model;
        this.specref = specref;
        this.vehTyp = vehTyp;
        this.vehicle = vehicle;
        this.servIntervals = servIntervals;
        this.dealerServiceControls = dealerServiceControls;
        this.locations = locations;
        this.locnimage = locnimage;
        this._location = _location;
        this.dealerAvailability = dealerAvailability;
        this.calendarStartDate = calendarStartDate;
        this.selectedDate = selectedDate;
        this.selectedTime = selectedTime;
        this.isMOT = isMOT;
        this.servType = servType;
        this.servOptionCosts = servOptionCosts;
        this.servAnnualInspectionLbl = servAnnualInspectionLbl;
        this.servAnnualInspectionCost = servAnnualInspectionCost;
        this.servTechOptionSts = servTechOptionSts;
        this.servTechOptions = servTechOptions;
        this.techoptECS = techoptECS;
        this.techoptACS = techoptACS;
        this.techoptBFC = techoptBFC;
        this.techoptCoolant = techoptCoolant;
        this.techoptPollen = techoptPollen;
        this.techoptGearbox = techoptGearbox;
        this.techoptCambelt = techoptCambelt;
        this.annualInspect = annualInspect;
        this.seasonalCheck = seasonalCheck;
        this.tyreCheck = tyreCheck;
        this.adBlue = adBlue;
        this.generalRepMain = generalRepMain;
        this.recall = recall;
        this.selectedService = selectedService;
        this.dealerCourtesyAvailable = dealerCourtesyAvailable;
        this.courtesyCar = courtesyCar;
        this.whileUwait = whileUwait;
        this.firstName = firstName;
        this.surname = surname;
        this.email = email;
        this.telephone = telephone;
        this.comments = comments;
        this.Address_1 = Address_1;
        this.Address_2 = Address_2;
        this.Town = Town;
        this.County = County;
        this.paymentType = paymentType;
        this.totalPrice = totalPrice;
        this.interval = interval;
        this.intervalMonth = intervalMonth;
        this.collectNdeliver = collectNdeliver;
        this.servcosts = servcosts;
        this.isPOA = isPOA;
        this.isTextBased = isTextBased;
        this.hasServicePlan = hasServicePlan;
        this.healthCheck = healthCheck;
        this.advisorAppointmentSts = advisorAppointmentSts;
        this.servSeasonalAirConSrvPrice = servSeasonalAirConSrvPrice;
        this.servSeasonalCombinedPrice = servSeasonalCombinedPrice;
        this.servSeasonalDetoxPackPrice = servSeasonalDetoxPackPrice;
        this.servSeasonalBronzePackPrice = servSeasonalBronzePackPrice;
        this.servSeasonalGoldPrice = servSeasonalGoldPrice;
        this.servSeasonalSilverSrvPrice = servSeasonalSilverSrvPrice;
        this.servCollectnDeliverCost = servCollectnDeliverCost;
        this.servCollectnDeliverLblOvr = servCollectnDeliverLblOvr;
        this.keyDropOff = keyDropOff;
        this.isSelectedDateCorrect = isSelectedDateCorrect;
        this.Q658 = Q658;
    }
    Object.defineProperty(FormData.prototype, "location", {
        get: function () {
            return this._location;
        },
        set: function (location) {
            if (!location) {
                this.courtesyCar = '';
            }
            this._location = location;
        },
        enumerable: true,
        configurable: true
    });
    return FormData;
}());
export { FormData };
var First = /** @class */ (function () {
    function First() {
    }
    return First;
}());
export { First };
var AddressList = /** @class */ (function () {
    // addressList: Array<any>;
    function AddressList(addressLines) {
        this.addressLines = addressLines;
    }
    return AddressList;
}());
export { AddressList };
var EnquiryCreate = /** @class */ (function () {
    function EnquiryCreate(customer, pagerefc, trackerref, uniquerefc, customer_hash, formref, session_c, Q57, Q2, Q3, Q9, Q7, Q8, Q119, Q120, Q45, Q41, Q626, Q282, Q188, Q658, Q283, Q99, Q91, Q186, Q285, Q284, Q623, Q68, Q70, Q71, Q72, Q101, Q183, encregno, annualInspect, TotalServiceCost) {
        this.customer = customer;
        this.pagerefc = pagerefc;
        this.trackerref = trackerref;
        this.uniquerefc = uniquerefc;
        this.customer_hash = customer_hash;
        this.formref = formref;
        this.session_c = session_c;
        this.Q57 = Q57;
        this.Q2 = Q2;
        this.Q3 = Q3;
        this.Q9 = Q9;
        this.Q7 = Q7;
        this.Q8 = Q8;
        this.Q119 = Q119;
        this.Q120 = Q120;
        this.Q45 = Q45;
        this.Q41 = Q41;
        this.Q626 = Q626;
        this.Q282 = Q282;
        this.Q188 = Q188;
        this.Q658 = Q658;
        this.Q283 = Q283;
        this.Q99 = Q99;
        this.Q91 = Q91;
        this.Q186 = Q186;
        this.Q285 = Q285;
        this.Q284 = Q284;
        this.Q623 = Q623;
        this.Q68 = Q68;
        this.Q70 = Q70;
        this.Q71 = Q71;
        this.Q72 = Q72;
        this.Q101 = Q101;
        this.Q183 = Q183;
        this.encregno = encregno;
        this.annualInspect = annualInspect;
        this.TotalServiceCost = TotalServiceCost;
    }
    return EnquiryCreate;
}());
export { EnquiryCreate };
