import { Injectable } from '@angular/core';
import { FormData, EnquiryCreate, AddressList } from '../models/formData';
import { Vehicle } from '../models/vehicle';
import { ServiceDetails } from '../models/serviceDetails';
import { Location } from '../models/location';
import { BehaviorSubject, Observable } from 'rxjs';
import DateChecker from 'src/app/utils/date-checker';
import { BrandsConfigService } from './brands-config.service';

declare var session_c: string;
declare var unique_ref_c: string;
declare var page_ref: string;
declare var tracker_ref: string;
declare var formRef: any;
declare var custRef: string;
declare var custHash: string;
declare var dataLayer: any[];

@Injectable()

export class FormDataService {
  // tslint:disable-next-line:max-line-length
  formData = new FormData('', '', '', false, '', '', '', '', '', '', '', '', '', '', '', '', [], [], [], '', '', [], new Date(), '', '', false, 'Service', null, '', '', '', [], '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'No', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', false, false, false, '', '', '', '', '', '', '', '' ,'', '');
  // tslint:disable-next-line:max-line-length
  enquiryCreate = new EnquiryCreate('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
  vehicle = Vehicle;
  serviceDetails = ServiceDetails;
  selectedLocation: any;
  location: Location;
  addressLines: any;
  addressList = new AddressList([]);
  annualInspect: string;

  private _serviceDetailsResponse: BehaviorSubject<ServiceDetails>;
  serviceDetailsResponse$: Observable<ServiceDetails>;

  constructor(private brandsConfigService: BrandsConfigService) {
    // Get the location pathname
    const pathname = window.location.pathname;

    // Check the window location pathname
    switch (true) {
      // If on seasonal-offers page selected seasonal check by default
      case pathname.includes('seasonal'):
        this.formData.servType = '';
        this.formData.seasonalCheck = 'Yes';
        break;
    }

    // Store the service details response into an observable
    this._serviceDetailsResponse = new BehaviorSubject(null);
    this.serviceDetailsResponse$ = this._serviceDetailsResponse.asObservable();
  }

  set serviceDetailsResponse(details: ServiceDetails) {
    this._serviceDetailsResponse.next(details);
  }

  get serviceDetailsResponse() {
    return this._serviceDetailsResponse.value;
  }

  // getFirst(): First {
  //   // Return the First data
  //   const first: First = {
  //     vehicleReg: this.formData.vehicleReg,
  //     mileage: this.formData.mileage,
  //     postcode: this.formData.postcode,
  //     isMOT: this.formData.isMOT
  //   };
  //   return first;
  // }

  setDataLayerDetails() {
    const myForm = document.forms['form_48'];
    const servicesForm = myForm.elements['services-field'];
    const services = Array.from(servicesForm.elements)
      .filter((i: HTMLInputElement) => i.checked)
      .map((i: HTMLInputElement) => i.name);
    const Customer = Array.from(myForm.elements['splanmotCustomer'])
      .filter((i: HTMLInputElement) => i.checked)
      .map((i: HTMLInputElement) => i.name);
    const REG = myForm.elements['Q57'].value;
    const Mileage = myForm.elements['Q72'].value;
    const Postcode = myForm.elements['Q9'].value;
    const MOT = typeof myForm.elements['yesnomotab'] !== 'undefined'
      ? myForm.elements['yesnomotab'].value === 'Yes'
      : false;
    const extraMOT = typeof myForm.elements['servType'] !== 'undefined'
      ? myForm.elements['servType'].checked
      : false;
    const Courtesy = typeof myForm.elements['is-courtesy-car'] !== 'undefined'
      ? myForm.elements['is-courtesy-car'].checked
      : false;
    const Appointment = typeof myForm.elements['Q99'] !== 'undefined'
      ? myForm.elements['Q99'].value
      : '';
    const BookingTime = typeof myForm.elements['selectedTime'] !== 'undefined'
      ? myForm.elements['selectedTime'].value
      : '';
    // const firstName = typeof myForm.elements['Q2'] !== 'undefined'
    //   ? myForm.elements['Q2'].value
    //  : '';
    // const lastName = typeof myForm.elements['Q3'] !== 'undefined'
    //  ? myForm.elements['Q3'].value
    //  : '';
    const email = typeof myForm.elements['Q41'] !== 'undefined'
      ? myForm.elements['Q41'].value
      : '';
    const payment = typeof myForm.elements['paymentType'] !== 'undefined'
     ? myForm.elements['paymentType'].value
     : '';
    const address =  document.getElementById('address') !== null
      ? document.getElementById('address').textContent
      : '';
    (window as any).dataLayer.push({
      event: 'serviceBookingServiceDetails',
      service: {
      type: services,
        Customer,
        REG,
        Mileage,
        Postcode,
        MOT,
        Option: {
          extras: {
            MOT: extraMOT,
            Courtesy,
            // Collection: boolean, // where is this?
            Appointment,
            BookingTime,
          },
          user: {
            address,
            email,
            payment,
          }
        }
      }
    });
  }

  setDataLayer(url: string, description: string) {
    dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': url,
      'virtualPageTitle': description
    });
    // console.log(dataLayer);
  }
  setDataLayerProduct(product, price) {
    dataLayer.push({
      'event': 'ProductSelected',
      'productType': product,
      'productPrice': price
    });
  }


  getServiceDetails(): ServiceDetails {
    // console.log('getServiceDetails from Form Data Service');
    // if (this.formData.isMOT) {
    //   console.log('MOT is selected, servtype should be ServiceMOT');
    // } else {
    //   console.log('MOT is NOT selected, servtype should be Service');
    // }

    // Return the First data
    const serviceDetails: ServiceDetails = {
      locnimage: this.formData.locnimage,
      servIntervals: this.formData.servIntervals,
      dealerServiceControls: this.formData.dealerServiceControls,
      locations: this.formData.locations,
      location: this.formData.location,
      dealerAvailability: this.formData.dealerAvailability,
      dealerCourtesyAvailable: this.formData.dealerCourtesyAvailable,
      selectedDate: this.formData.selectedDate,
      selectedTime: this.formData.selectedTime,
      whileUwait: this.formData.whileUwait,
      servType: this.formData.servType,
      isMot: this.formData.isMOT,
      servOptionCosts: this.formData.servOptionCosts,
      seasonalCheck: this.formData.seasonalCheck,
      tyreCheck: this.formData.tyreCheck,
      adBlue: this.formData.adBlue,
      generalRepMain: this.formData.generalRepMain,
      recall: this.formData.recall,
      selectedService: this.formData.selectedService,
      courtesyCar: this.formData.courtesyCar,
      selectedInterval: this.formData.intervalMonth,
      collectNdeliver: this.formData.collectNdeliver,
      healthCheck: this.formData.healthCheck,
      advisorAppointmentSts: this.formData.advisorAppointmentSts,
      Q658: this.formData.Q658,
      seasonalDetox: this.formData.servSeasonalDetoxPackPrice,
      seasonalAirConSrv: this.formData.servSeasonalAirConSrvPrice,
      collectnDeliverCost: this.formData.servCollectnDeliverCost,
      collectnDeliverLblOvr: this.formData.servCollectnDeliverLblOvr,
      seasonalCombined: this.formData.servSeasonalCombinedPrice,
      seasonalBronze: this.formData.servSeasonalBronzePackPrice,
      seasonalSilver: this.formData.servSeasonalSilverSrvPrice,
      seasonalGold: this.formData.servSeasonalGoldPrice,

      // serviceAvailabilityData: this.formData.dateAvailability
      // dateAvailability: {},
      // serviceAvailabilityData: [],
      // timeSlots: []
    };
    return serviceDetails;
  }

  getVehicle(): Vehicle {
    // Return the Vehicle data
    const vehicle: Vehicle = {
      make: this.formData.make,
      model: this.formData.model,
      specref: this.formData.specref,
      vehicle: this.formData.vehicle,
      basecolour: this.formData.basecolour,
      canservice: this.formData.canservice,
      capcode: this.formData.capcode,
      dofr: this.formData.dofr,
      encregno: this.formData.encregno,
      fuel: this.formData.fuel,
      vehTyp: this.formData.vehTyp,
      image: this.formData.image
    };
    return vehicle;
  }

  getLocation(): Location {
    const location: Location = {
      locnDsc: this.formData.location
    };
    return location;
  }


  getAddressList(): AddressList {
    const addressLines: AddressList = {
      addressLines: this.addressLines
    };
    return addressLines;
  }


  setVehicle(data: Vehicle) {
    this.formData.make = data.make;
    this.formData.model = data.model;
    this.formData.specref = data.specref;
    this.formData.vehicle = data.vehicle;
    this.formData.basecolour = data.basecolour;
    this.formData.canservice = data.canservice;
    this.formData.capcode = data.capcode;
    this.formData.dofr = data.dofr;
    this.formData.encregno = data.encregno;
    this.formData.fuel = data.fuel;
    this.formData.vehTyp = data.vehTyp;
    this.formData.image = data.image;
  }

  setServiceDetails(data: ServiceDetails) {
    // Update the service details response
    this.serviceDetailsResponse = data;

    this.formData.isPOA = false;
    this.formData.isTextBased = false;
    // The below code seperates the Locations to show without miles
    const locationsArray = data.locations;
    for (let i = 0; i < locationsArray.length; i++) {
      locationsArray.splice(i + 1, 1);
    }

    // If vehicle has a service plan, default to first service option
    if (this.formData.hasServicePlan) {
      // console.log(this.formData)
      this.formData.intervalMonth = data.servIntervals[0].servmonths;
      this.formData.paymentType = 'splan';
      this.formData.interval = data.servIntervals[0].servmonths + ' Months ' + data.servIntervals[0].servmiles +
        ' Miles ' + data.servIntervals[0].servcosts + ' Duration ' + data.servIntervals[0].servtimes;
      this.setPrice(data.servIntervals[0].servcosts);
      this.setInterval(this.formData.interval, this.formData.intervalMonth);
      this.setSelectedService(1);
    }

    // Check if the service interval has a POA/Non Manufacturer options
    if (data.servIntervals[0].servmonths === '0') {
      // If the service option has been selected
      if (typeof this.formData.servcosts !== 'undefined' && this.formData.servcosts !== '') {
        this.formData.isTextBased = true;
        // If the selected option is POA
        if (this.formData.servcosts.includes('POA')) {
          this.formData.isPOA = true;
          // Update the selected option with the correct value
          this.formData.servcosts = data.servIntervals[0].servcosts;
        } else {
          // If the selected option is Non Manufacturer
          this.formData.servcosts = data.servIntervals[1].servcosts;
        }
      }
      // If vehicle has a service plan, default to first POA service option
      if (this.formData.hasServicePlan) {
        this.formData.servcosts = data.servIntervals[0].servcosts;
      }
    }

    const intervals = data.servIntervals;
    const monthSelected = this.formData.intervalMonth;
    const getSelectedInterval = intervals.filter(i => i.servmonths === monthSelected)[0];
    //  console.log(data.dateAvailability.serviceAvailabilityData);
    this.formData.locnimage = data.locnimage;
    this.formData.servIntervals = data.servIntervals;
    this.formData.dealerServiceControls = data.dealerServiceControls;
    this.formData.advisorAppointmentSts = data.advisorAppointmentSts;
    this.formData.locations = locationsArray;
    this.formData.dealerAvailability = data.dealerAvailability;
    // this.formData.selectedTime = '';
    this.formData.location = data.dealerServiceControls && data.dealerServiceControls.locnDsc;
    this.formData.servAnnualInspectionLbl = data.servOptionCosts.servAnnualInspectionLbl;
    this.formData.servAnnualInspectionCost = data.servOptionCosts.servAnnualInspectionCost;
    this.formData.servSeasonalAirConSrvPrice = data.servOptionCosts.servSeasonalAirConSrvPrice;
    this.formData.servSeasonalDetoxPackPrice = data.servOptionCosts.servSeasonalDetoxPackPrice;
    this.formData.servSeasonalCombinedPrice = data.servOptionCosts.servSeasonalCombinedPrice;
    this.formData.servSeasonalBronzePackPrice = data.servOptionCosts.servSeasonalBronzePackPrice;
    this.formData.servSeasonalSilverSrvPrice = data.servOptionCosts.servSeasonalSilverSrvPrice;
    this.formData.servSeasonalGoldPrice = data.servOptionCosts.servSeasonalGoldPrice;
    this.formData.servCollectnDeliverCost = data.servOptionCosts.servCollectnDeliverCost;
    this.formData.servCollectnDeliverLblOvr = data.servOptionCosts.servCollectnDeliverLblOvr;

    if (data.servOptionCosts.servTechOptions !== undefined && data.servOptionCosts.servTechOptions !== undefined) {
      this.formData.servTechOptionSts = data.servOptionCosts.servTechOptionSts;
      this.formData.servTechOptions = data.servOptionCosts.servTechOptions;
    }

    if (typeof getSelectedInterval !== 'undefined') {
      this.formData.totalPrice = data.servOptionCosts.servTotalServiceCost;
    }

    if (data.servIntervals[0].servmonths === '0') {
      this.setPrice(data.servOptionCosts.servTotalServiceCost);
    }
  }

  setSelectedLocation(selectedLocation) {
    this.formData.location = selectedLocation;
    console.log('set selected location has been called');
  }

  setLocation(data: Location) {
    data.locnDsc = this.formData.location;
    console.log('set location has been called' + data.locnDsc);
  }

  setSelectedDate(selectedDate) {
    this.formData.selectedTime = '';
    this.formData.selectedDate = selectedDate;
    this.formData.isSelectedDateCorrect = DateChecker.checkDate(selectedDate);
  }

  setInterval(interval, month) {
    this.formData.interval = interval;
    this.formData.intervalMonth = month;
  }

  setPrice(price) {
    this.formData.totalPrice = price;
  }

  setSelectedTime(selectedTime) {
    this.formData.selectedTime = selectedTime;
  }

  setSelectedService(selectedService) {
    this.formData.selectedService = selectedService;
  }


  getSelectedDate() {
    const selectedDate = {
      selectedDate: this.formData.selectedDate
    };
    return selectedDate;
  }

  getSelectedTime() {
    const selectedTime = {
      selectedTime: this.formData.selectedTime
    };
    return selectedTime;
  }

  getFormData(): FormData {
    // Return the entire Form Data
    return this.formData;
  }

  getEnquiryData(): EnquiryCreate {
    // Return the entire Form Data
    return this.enquiryCreate;
  }

  getAddressLineData(): AddressList {
    // Return the entire Form Data
    return this.addressList;
  }


  setAddressList(data: AddressList) {
    this.addressList.addressLines = data;
    //  console.log(JSON.stringify(data) + ' setAddressList function call');
  }

  scrollToTop() {
    const el = document.getElementById('form_48'); // need to work out a better way to do this.
    el.scrollIntoView();
  }

  setEnquiryJSONCreate(data: FormData, vehicle: Vehicle) {
    const waitingAppointment = data.whileUwait ? 'Waiting appointment' : '';
    const collectNDeliver = data.collectNdeliver ? 'Collection and delivery' : '';
    const keyDropOff = data.keyDropOff ? ' Key Drop Off ' : '';

    this.enquiryCreate.customer = custRef;
    this.enquiryCreate.pagerefc = page_ref;
    this.enquiryCreate.trackerref = tracker_ref;
    this.enquiryCreate.uniquerefc = unique_ref_c;
    this.enquiryCreate.customer_hash = custHash;

    this.enquiryCreate.formref = formRef;
    this.enquiryCreate.session_c = session_c;

    this.enquiryCreate.Q57 = data.vehicleReg;
    this.enquiryCreate.Q72 = data.mileage;
    this.enquiryCreate.Q9 = data.postcode;
    this.enquiryCreate.Q7 = data.Address_1;
    this.enquiryCreate.Q8 = data.Address_2;
    this.enquiryCreate.Q119 = data.Town;
    this.enquiryCreate.Q120 = data.County;

    this.enquiryCreate.Q283 = data.courtesyCar;
    // tslint:disable-next-line: max-line-length
    this.enquiryCreate.Q282 = data.servType + ' ' + (data.seasonalCheck === 'Yes' ? 'Seasonal check ' : '')
      + (data.servOptionCosts && data.servOptionCosts.servOptionsSelected.servSeasonalDetoxSelected === 'yes'  ? 'Detox' : '')
      + (data.servOptionCosts && data.servOptionCosts.servOptionsSelected.servSeasonalAirConSelected === 'yes'  ? 'AirCon' : '')
      + (data.servOptionCosts && data.servOptionCosts.servOptionsSelected.servSeasonalCombinedSelected === 'yes'  ? 'Combined' : '')
      + (data.servOptionCosts && data.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected === 'yes'  ? 'Bronze' : '')
      + (data.servOptionCosts && data.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected === 'yes'  ? 'Silver' : '')
      + (data.servOptionCosts && data.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected === 'yes'  ? 'Gold' : '')
      + (data.tyreCheck === 'Yes' ? 'Tyre check ' : '')
      + (data.adBlue === 'Yes' ? 'AdBlue ' : '')
      + (data.generalRepMain === 'Yes' ? 'General repair and maintenance ' : '')
      + (data.recall === 'Yes' ? 'Recall ' : '')
      + (data.healthCheck === 'Yes' ? 'Health check' : '')
      + (data.techoptECS === 'yes' ? 'Emission Control Service ' : '')
      + (data.techoptACS === 'yes' ? 'Air Conditioning Service ' : '')
      + (data.techoptBFC === 'yes' ? 'Brake Fluid Change ' : '')
      + (data.techoptCoolant === 'yes' ? 'Coolant Change ' : '')
      + (data.techoptPollen === 'yes' ? 'Pollen Filter Change ' : '')
      + (data.techoptGearbox === 'yes' ? 'Gearbox Oil Service ' : '')
      + (data.techoptCambelt === 'yes' ? 'Cambelt Change' : '');
    this.enquiryCreate.Q188 = data.comments;
    this.enquiryCreate.Q658 = data.Q658;

    this.enquiryCreate.Q41 = data.email;
    this.enquiryCreate.Q45 = data.telephone;
    this.enquiryCreate.Q2 = data.firstName;
    this.enquiryCreate.Q3 = data.surname;

    this.enquiryCreate.Q99 = data.selectedDate + ' ' + data.selectedTime;
    this.enquiryCreate.Q91 = data.selectedTime;
    this.enquiryCreate.Q186 = data.location;

    this.enquiryCreate.Q284 = data.paymentType;
    // tslint:disable-next-line:max-line-length
    // + (data.servOptionCosts.servOptionsSelected ? data.interval : '')
    // tslint:disable-next-line: max-line-length
    this.enquiryCreate.Q285 = (data.interval ? data.interval : data.servAnnualInspectionLbl) + ' Current Mileage ' + data.mileage + ' Service extras '
      + waitingAppointment + collectNDeliver + keyDropOff;

    this.enquiryCreate.Q68 = vehicle.vehicle;
    this.enquiryCreate.Q70 = vehicle.make;
    this.enquiryCreate.Q71 = vehicle.model;
    this.enquiryCreate.Q72 = data.mileage;
    this.enquiryCreate.Q101 = vehicle.dofr;
    this.enquiryCreate.Q183 = vehicle.fuel;
    this.enquiryCreate.encregno = vehicle.encregno;
    this.enquiryCreate.annualInspect = data.annualInspect;

    this.enquiryCreate.TotalServiceCost = data.totalPrice;
  }

}
