import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VehicleLookupService = /** @class */ (function () {
    function VehicleLookupService(http) {
        this.http = http;
    }
    VehicleLookupService.prototype.findVehicle = function (registration, isMakeLevc) {
        if (isMakeLevc === void 0) { isMakeLevc = null; }
        return this.http.get('/content/api/json_veh.p?regno=' + registration + '&formref=' + formRef +
            '&uniquerefc=' + unique_ref_c + '&makeIsLEVC=' + isMakeLevc);
    };
    VehicleLookupService.ngInjectableDef = i0.defineInjectable({ factory: function VehicleLookupService_Factory() { return new VehicleLookupService(i0.inject(i1.HttpClient)); }, token: VehicleLookupService, providedIn: "root" });
    return VehicleLookupService;
}());
export { VehicleLookupService };
