var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
// Vehicle Details Component
import { OnInit, AfterViewInit } from '@angular/core';
import { VehicleLookupService } from '../../services/vehicle-lookup.service';
import { PostcodeLookupService } from '../../services/postcode-lookup.service';
import { GetServiceDetailsService } from '../../services/get-service-details.service';
import { FormDataService } from '../../services/form-data.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { BrandsConfigService } from 'src/app/services/brands-config.service';
var VehicleDetailsComponent = /** @class */ (function () {
    function VehicleDetailsComponent(vehicleLookupService, getServiceDetailsService, formDataService, postcodeLookupService, brandsConfigService) {
        this.vehicleLookupService = vehicleLookupService;
        this.getServiceDetailsService = getServiceDetailsService;
        this.formDataService = formDataService;
        this.postcodeLookupService = postcodeLookupService;
        this.brandsConfigService = brandsConfigService;
        this.vehicle = null;
        // RxJS registration subject
        this.registrationChangeSubject = new BehaviorSubject('');
        this.registristrationChangeObservable = this.registrationChangeSubject.asObservable();
        // ngModel of postcode declared as string for use in RxJS subject
        this.isJlr = window.location.href.search('farnelljlr') !== -1 ? true : false;
        this.postCodeRegex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gi;
        this.isValidPostCode = false;
        // RxJS postcode subject
        this.postcodeChangeSubject = new Subject();
        this.servTypeSelected = true;
        this.vehicleIsMercedes = false;
        this.vehicleAllowed = true;
    }
    VehicleDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formData = this.formDataService.getFormData();
        this.enquiryCreate = this.formDataService.getEnquiryData();
        // The below cde is to read the reg value from the session storage if
        // it comes from a widget such as the find a dealer widget
        if (sessionStorage.getItem('car reg') !== null) {
            this.formData.vehicleReg = sessionStorage.getItem('car reg');
            // this.getVehicleDetails();
            this.onRegistrationChange(this.formData.vehicleReg);
        }
        // RxJS subject based debouncing, runs API fetch on keyup once a second
        this.registristrationChangeObservable.pipe(tap(function (e) {
            _this.emptyField = !e ? true : false;
            _this.vehicleBeingFetched = true;
            _this.isLevcVehicleStr = _this.selfCheckedLevcVehicle ? 'YES' : '';
        }), debounceTime(1000), switchMap(function (model) {
            if (!_this.emptyField) {
                return _this.vehicleLookupService.findVehicle(model, _this.isLevcVehicleStr);
            }
            else {
                return [];
            }
        })).subscribe(function (data) {
            _this.vehicle = data;
            _this.selfCheckedSplan = (_this.vehicle['hasServicePlan'] === 'yes') ? true : _this.selfCheckedSplan;
            _this.selfCheckedMOTYes = (_this.vehicle['hasServicePlanWithMOT'] === 'yes') ? true : _this.selfCheckedMOTYes;
            _this.vehicleBeingFetched = false; // response returned from vehicle lookup service
            // If vehicle details have been found from the lookup service
            if (_this.vehicle.vehicle) {
                _this.formDataService.setVehicle(_this.vehicle);
                _this.checkAllowedVehicles();
                _this.vehicleFound = true;
            }
            else {
                _this.vehicleFound = false;
                console.log('Vehicle not found!');
            }
            // Check whether vehicle type is supported for servicing on current brand
            _this.isServicedVehicleType =
                _this.brandsConfigService.getConfigValue('servicedVehicleTypes').includes(_this.vehicle.vehTyp)
                    || (_this.brandsConfigService.getConfigValue('servicedVehicleTypes') === "any");
        }, function (err) { return console.log("getVehicleDetails encountered an error : " + err); });
        // Same for postcode
        this.postcodeChangeSubject.pipe(tap(function () { return _this.postCodeBeingFetched = true; }), debounceTime(1000), distinctUntilChanged()).subscribe(function (model) {
            _this.formData.postcode = model;
            _this.postCodeBeingFetched = false;
            _this.isValidPostCode = _this.postCodeRegex.test(model);
            if (_this.isValidPostCode) {
                _this.getAddressList();
            }
        });
        this.isTaxiCentre = window.location.href.includes('thetaxicentre');
        this.needLevcSelection = this.isTaxiCentre && this.selfCheckedLevcVehicle == null;
    };
    VehicleDetailsComponent.prototype.ngAfterViewInit = function () {
        this.formDataService.setDataLayer(window.location.href + 'vehicle-details', 'Vehicle details page of service booking form');
        window.dataLayer.push({ event: 'serviceBookingVehicleDetails' });
    };
    VehicleDetailsComponent.prototype.updateDataLayer = function () {
        this.formDataService.setDataLayer(window.location.href + 'service-details', 'Service details page of service booking form');
        this.formDataService.setDataLayerDetails();
    };
    /*testing() {
         const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
         this.http.get('https://www.bristolstreet.co.uk/content/api/json_veh.p?regno=VK12NWS').subscribe(
             vals => console.log(vals),
             err => console.log(`There was an error while making this http request: ${err}`));
    }*/
    VehicleDetailsComponent.prototype.onServTypeChanged = function () {
        if (this.formData.servType === '' && this.formData.seasonalCheck === '' && this.formData.tyreCheck === '' && this.formData.adBlue === '' && this.formData.generalRepMain === '' && this.formData.recall === '' && this.formData.healthCheck === '') {
            this.servTypeSelected = false;
        }
        else {
            this.servTypeSelected = true;
        }
    };
    // Fetches vehicle details by sending a Vehicle Registration, returns empty array if not found
    /*getVehicleDetails() {
        this.vehicleLookupService.findVehicle(this.formData.vehicleReg).subscribe(
            (data: any) => {
                this.vehicle = data;
                if (this.vehicle.vehicle) {
                    this.formDataService.setVehicle(this.vehicle);
                    this.vehicleFound = true;
                } else {
                    this.vehicleFound = false;
                }
            },
            (err: any) => console.log(`getVehicleDetails encountered an error : ${err}`)
        );
    }*/
    // Sends the inputted registration to RxJs Subject
    VehicleDetailsComponent.prototype.onRegistrationChange = function (query) {
        this.formData.vehicleReg = query.toUpperCase();
        this.registrationChangeSubject.next(this.formData.vehicleReg);
    };
    // Sets the vehicle form data depending on whether the LEVC vehicle checkbox is yes or no
    VehicleDetailsComponent.prototype.onLevcVehicleCheckedChange = function () {
        // If the reg number has been entered, rerun the reg number 
        if (this.formData.vehicleReg) {
            this.onRegistrationChange(this.formData.vehicleReg);
        }
        // Removes any previous saved location 
        // This means only Nissan Newcastle shows if LEVC selected
        // Or it doesn't auto-set to Nissan Newcastle if not an LEVC vehicle
        this.formData.location = '';
    };
    // API call to check for valid postcode. Returns empty array if postcode not found.
    VehicleDetailsComponent.prototype.getAddressList = function () {
        var _this = this;
        this.postcodeLookupService.getAllAddresses(this.postcodeModel).subscribe(function (data) {
            _this.addressList = data.Addresslines;
            if (_this.addressList[0]) {
                _this.formDataService.setAddressList(data);
                _this.postcodeFound = true;
                _this.formData.postcodeFound = true;
            }
            else {
                _this.postcodeFound = false;
                _this.formData.postcodeFound = false;
            }
        }, function (err) { return console.log('Error with getAllAddressList'); });
    };
    // isHonda() {
    //     const url: string = window.location.href;
    //     return (url.includes('vertuhonda')) ? true : false;
    // }
    // Sends the inputted postcode to RxJs Subject
    VehicleDetailsComponent.prototype.onPostcodeChange = function (query) {
        this.postcodeChangeSubject.next(query.toUpperCase());
    };
    // removes error state from Postcode Input
    VehicleDetailsComponent.prototype.unsetPostcodeFound = function () {
        this.postcodeFound = null;
    };
    VehicleDetailsComponent.prototype.getServiceQuote = function () {
        var _this = this;
        if (this.selfCheckedSplan) {
            if (this.formData.hasServicePlan = true && this.selfCheckedMOTYes === true) {
                this.formData.Q658 = 'Service Plan with MOT';
            }
            else if (this.formData.hasServicePlan = true && (this.selfCheckedMOTNO === true || this.selfCheckedMOTNO === undefined)) {
                this.formData.Q658 = 'Service Plan without MOT';
            }
        }
        if (this.formData.hasServicePlan) {
            this.formDataService.setSelectedService(1);
        }
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, '', this.formData).subscribe(function (data) {
            _this.serviceDetails = data;
            try {
                _this.formData.calendarStartDate = new Date(parseInt(Object.keys(data.dealerAvailability)[0].substr(6, 4)), parseInt(Object.keys(data.dealerAvailability)[0].substr(3, 2)) - 1);
            }
            catch (err) {
                _this.formData.calendarStartDate = null;
            }
            _this.formData.totalPrice = data.servOptionCosts.servTotalServiceCost;
            _this.formDataService.formData.servOptionCosts = data.servOptionCosts;
        }, function (err) { return console.log(err); }, function () { return _this.formDataService.setServiceDetails(__assign({}, _this.serviceDetails)); });
        if (this.formData.servType === 'MOT') {
            this.formData.isMOT = true;
            this.formData.intervalMonth = 'MOT';
        }
        this.formDataService.setEnquiryJSONCreate(this.formData, this.vehicle);
        this.formDataService.scrollToTop();
    };
    VehicleDetailsComponent.prototype.checkAllowedVehicles = function () {
        if (this.isTaxiCentre) {
            this.vehicleAllowed = this.vehicle.make !== 'LTI';
        }
        // if (this.isJlr) {
        //   if (this.vehicle.make !== 'Jaguar' && this.vehicle.make !== 'Land Rover') {
        //     this.vehicleAllowed = false;
        //   } else {
        //     this.vehicleAllowed = true;
        //   }
        // }
    };
    return VehicleDetailsComponent;
}());
export { VehicleDetailsComponent };
